<template>
    <section class="know-section">
        <div class="container my-container">
            <div class="know-content">
                <div v-if="!isLoading" class="row no-gutters">
                    <div class="col-md-3">
                        <div class="know-list position-sticky">
                            <div :key="targethash" class="nav flex-column" id="nav-tab" role="tablist">
                                <a
                                    v-for="(group, index) in knowGroups"
                                    :key="index"
                                    :class="classTabAction(generateId(group.title), index)"
                                    :id="`${generateId(group.title)}-tab`"
                                    data-bs-toggle="tab"
                                    :data-bs-target="`#${generateId(group.title)}`"
                                    type="button"
                                    role="tab"
                                    :aria-controls="generateId(group.title)"
                                    aria-selected="true"
                                    @click="currentFaqContent=group.description"
                                >
                                    {{ group.title }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="know-main-container">
                            <div class="title-faq" >
                                <p v-html="getDefaultFaqContent"></p>
                            </div>
                            <div class="know-content-tab">
                            <!-- Tab panes -->
                                <div :key="targethash" class="tab-content" id="nav-tabContent">
                                    
                                    <div
                                        v-for="(item, index) in getItems"
                                        :key="index"
                                        :class="classTabItem(item.id, index)"
                                        :id="item.id"
                                        role="tabpanel"
                                        aria-labelledby="nav-ministry-tab"
                                    >
                                        <div v-for="(child, cindex) in item.children" :key="cindex" class="q-contianer">
                                            <a class='q-item' data-bs-toggle="collapse" :href="`#qa-${cindex+1}`" role="button" aria-expanded="false" :aria-controls="`qa-${cindex+1}`">
                                                <p class="q-text">
                                                    <span><i class="fas fa-plus-circle"></i></span> {{ child.title }}
                                                </p>
                                            </a>
                                            <div class="a-container collapse" :id="`qa-${cindex+1}`" v-html="child.content"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { useFind } from '@feathersjs/vuex';
import { computed, defineComponent, getCurrentInstance } from 'vue';
import { mapState, mapGetters } from 'vuex';

export default defineComponent({
    data() {
        return {
            currentFaqContent: '',
            currentInterval: null,
            targethash: window.location.hash
        };
    },
    setup() {
        const vm = getCurrentInstance();
        const { KnowGroups, KnowItems }  = vm.appContext.provides.$FeathersVuex.api;
        const knowGroups = useFind({ model: KnowGroups, params: computed(() => {
            return {
                query: { $limit: 1000, $sort: { sort: 1 } }
            }
        }), immediate: true });
        const knowItems = useFind({ model: KnowItems, params: computed(() => {
            return {
                query: { $limit: 1000, $sort: { sort: 1 } }
            }
        }), immediate: true });
        return {
            knowGroups: knowGroups.items,
            knowItems: knowItems.items
        };
    },
    computed: {
        isLoading() {
            return this.groupsLoading || this.itemsLoading;
        },
        getItems() {
            return this.knowGroups.map(item => {
                return {
                    id: this.generateId(item.title),
                    children: this.findItems({
                        query: {
                            know_group_id: item.id,
                            $limit: 1000,
                            $sort: { sort: 1 }
                        }
                    }).data
                }
            });
        },
        getTargetTab() {
            const target = this.targethash/*window.location.hash*/.replace(/^#/g, '');
            if(target.length !== 0) {
                return target;
            } else {
                return null;
            }
        },
        getDefaultFaqContent() {
            if(this.currentFaqContent.length !== 0) {
                return this.currentFaqContent;
            }
            const ttab = this.getTargetTab;
            return this.knowGroups.filter((item, index) => {
                const id = this.generateId(item.title);
                return (!ttab && index === 0) || (id.replace(/^nav-/g, '') === ttab);
            })[0].description;
        },
        ...mapState('know-groups', { groupsLoading: 'isFindPending' }),
        ...mapState('know-items', { itemsLoading: 'isFindPending' }),
        ...mapGetters('know-items', { findItems: 'find' }),
    },
    mounted() {
        this.currentInterval = setInterval(() => {
            if(this.targethash !== window.location.hash) {
                this.targethash = window.location.hash;
                console.log(this.targethash);
                // if(!window.location.hash) {
                //     window.scrollTo(0, 0);
                // } else {
                //     const el = window.$(window.location.hash).get(0);
                //     if(el) this.scrollMeTo(el);
                // }
            }
        }, 100);
    },
    methods: {
        classTabAction(id, index) {
            const ttab = this.getTargetTab;
            return {
                'nav-link': true,
                'active': (!ttab && index === 0) || (id.replace(/^nav-/g, '') === ttab)
            };
        },
        classTabItem(id, index) {
            const ttab = this.getTargetTab;
            const isActive = (!ttab && index === 0) || (id.replace(/^nav-/g, '') === ttab);
            return {
                'tab-pane': true,
                'fade': true,
                'show': isActive,
                'active': isActive
            };
        },
        generateId(name) {
            return 'nav-' + String(name).trim().toLowerCase().replace(/\W/g, '-').replace(/-+/g, '-');
        }
    }
})
</script>
